<template>
  <div className="animated fadeIn">

    <b-button variant="success" class="btn btn-md float-right mb-3" style="margin-top: 20px;  right: 115px; z-index: 100; position: absolute;" :to="{ name: 'CommissionAdd', params: {product: $route.params.product } }">Add Commission Value</b-button>

      <v-server-table :columns="columns" :data="data" :options="options" :theme="theme" id="dataTable" class="table">

          <template slot="active" slot-scope="props">
            <i v-if="props.row.active === true || props.row.active === 'true'" class="fas fa-check text-success"></i>
            <i v-else class="fas fa-times text-danger"></i>
          </template>

          <template slot="actions" slot-scope="props">
            <b-button variant="secondary" :to="{ name: 'CommissionView', params: { product: $route.params.product, id: props.row.id } }">View</b-button>
          </template>

      </v-server-table>

  </div>
</template>

<script>
  import Vue from 'vue'
  import {ServerTable} from 'vue-tables-2'
  import axios from '../../../shared/axios/auth'

  Vue.use(ServerTable)

  export default {
    name: 'DataTable',
    components: {
      ServerTable
    },
    data: function () {
      return {
        columns: [
          /*'id',*/ 'value', 'percent', 'actions'],
        data: [],
        checkedData: [],
        fields: [
         /* {
          name: '__checkbox',
          titleClass: 'center aligned',
          dataClass: 'center aligned'
        },*/
        ],
        options: {
          requestFunction: (data) => {
              return axios.get('/commission/?product=' + this.$route.params.product, {
                  params: data
              }).catch(error => {
                // Unauthorised
                if (error.response.status === 401) {
                  this.$store.dispatch('logout');
                }
                console.log('quotes error');
                console.log(error.response)
              });
          },
          responseAdapter: function(resp) {
            var data = this.getResponseData(resp);
            return { data: data.data.data, count: data.data.pagination.total }
          },
          headings: {
            value: 'Value',
            percent: '%',
            csid: 'CSID',
          },
          sortable: [],
          filterable: [],
          sortIcon: { base:'fa', up:'fa-sort-asc', down:'fa-sort-desc', is:'fa-sort' },
        },
        useVuex: false,
        theme: 'bootstrap4',
        template: 'default'
      }
    },
    methods: {
      selectAll() {
           let length = this.tableData.length
           this.isRead = !this.isSelected;

           for (let i = 0; i < length; i++) {
               this.tableData[i].isChecked=this.isSelected;
           }
      },
      getStatus (status) {
        return status === null ? 'success' : status === 1 ? 'warning' : status === 'Banned' ? 'danger' : 'primary'
      },
      getPolicies() {
        axios.get('/commission/?product=' + this.$route.params.product).then(
          response => (
            this.data = response.data
          )
        ).catch(error => {
              // Unauthorised
              if (error.response.status === 401) {
                this.$store.dispatch('logout');
              }
              console.log('post error');
              console.log(error.response);
          });

        return this.data
      }
    },
    mounted() {
      //this.getPolicies()
    },
    watch: {
      $route(to, from) {
        // react to route changes...
        this.$router.go(this.$router.currentRoute).then(
          this.getPolicies()
        );

        /*this.$router.replace(to.fullPath).catch(error => {
          console.log(error)
          this.getPolicies()
        }).then(
          this.getPolicies()
        );*/

      }
    }
  };
</script>

<style>

  #dataTable  table {
    background-color: #fff;
  }
  .form-inline label {
      justify-content: left;

  }


</style>
